<template>
  <v-app>
    <contact-form
      :name="name"
      :authentication-token="authenticationToken"
      :required-fields="requiredFields"
      buttonText="Senden"
    >
      <contact-fields :exclude="exclude"></contact-fields>
    </contact-form>
  </v-app>
</template>

<script>
import ContactForm from "./ContactForm";
import ContactFields from "./ContactFieldsSuchprofil";

export default {
  name: "SuchprofilFormApp",

  components: {
    ContactForm,
    ContactFields,
  },

  data() {
    return {
      name: "formhaus_suchprofil",
    };
  },

  mounted() {
    if (this.$root.includeFields && this.$root.includeFields.includes("mim2023")) {
      this.name = "formhaus_suchprofil_mim";
      this.$root.contactForm.successTextMsg = "Suchprofil erstellt!";
    }
  },

  computed: {
    requiredFields() {
      return this.$yeti.formRequiredFields(this.name);
    },

    authenticationToken() {
      if (this.$store && this.$store.state) {
        return this.$store.state.auth.authenticationToken;
      }

      return null;
    },

    loggedIn() {
      if (this.$store && this.$store.state) {
        return this.$store.state.user.loggedIn;
      }

      return false;
    },

    exclude() {
      let ignoreFields = [];
      if (this.loggedIn) {
        ignoreFields = ["email"];
      }

      let el = [];
      if (this.$root.exclude) {
        el = this.root.exclude;
      }

      return ignoreFields.concat(el);
    },
  },
};
</script>
