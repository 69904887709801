<template>
  <div class="block d-lg-flex contact-range-input">
    <v-range-slider
      :id="id"
      v-model="val"
      dense
      :max="end"
      :min="start"
      :label="mylabel + ' von ' + labelHint"
      :error="!!error"
      :error-messages="error"
      :value="val"
      :step="step"
    ></v-range-slider>
  </div>
</template>

<script>
export default {
  name: "ContactRangeInput",

  props: ["name", "group", "label", "start", "end", "step", "format"],

  data() {
    return {
      val: [parseInt(this.start), parseInt(this.end)],
    };
  },

  mounted() {
    if (this.group == "contact") {
      this.val = this.$root.contactForm.contact[this.name];
    }
    this.$root.contactForm.registerField(this);
  },

  beforeDestroy() {
    this.$root.contactForm.unregisterField(this);
  },

  computed: {
    invalid() {
      return this.$root.contactForm.errors.indexOf(this.name) > -1;
    },

    required() {
      return this.$root.contactForm.isRequired(this.name);
    },

    error() {
      if (this.invalid) {
        return "Pflichtfeld";
      }

      return null;
    },

    mylabel() {
      if (this.required) {
        return `${this.label}*`;
      }

      return this.label;
    },

    labelHint() {
      if (this.val) {
        return `${
          this.format === "EUR"
            ? new Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: "EUR",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(this.val[0])
            : this.val[0]
        } - ${
          this.format === "EUR"
            ? new Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: "EUR",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(this.val[1])
            : this.val[1]
        }`;
      }

      return "";
    },

    id() {
      return `contact__${this.name}`;
    },
  },

  methods: {
    mapValue() {
      if (this.group == "contact") {
        this.$root.contactForm.contact[this.name] = this.val;
      }
    },
  },
};
</script>
